import React from 'react'

const MainContent = () => {
  return (
    <div>
    
    <h1>MainContent here!!!!</h1>
    
    
    </div>
  )
}

export default MainContent